/*
 * Copyright ©  2018 - 2050 EasyLinkz Limited.
 *
 * All rights reserved.
 *
 * Email: super@easylinkz.com
 *
 * Licensed under the proprietary license.
 *
 * The source code contained herein is, and remains the property of EasyLinkz Limited.
 *
 * Dissemination, reproduction or other use of this code is strictly forbidden unless prior written permission
 * is obtained from EasyLinkz Limited.
 *
 * This software is provided by the author “as is” and any express or implied warranties, including,
 * but not limited to, the implied warranties of MERCHANTABILITY and FITNESS FOR A PARTICULAR PURPOSE are disclaimed.
 * In no event shall the author be liable for any direct, indirect, incidental, special, exemplary,
 * or consequential damages (including, but not limited to, procurement of substitute goods or services,
 * loss of use, data, or profits, or business interruption) however caused and on any theory of liability,
 * whether in contract, strict liability, or tort (including negligence or otherwise)
 * arising in any way out of the use of this software, even if advised of the possibility of such damage.
 *
 * You should have received a copy of the EasyLinkz Limited licence along with this program,
 * if not, write to the super@easylinkz.com.
 *
 */

import {HttpClient} from '@/services/http.service';
import {AboutUsLinkInterface} from '@/shared/interfaces/store/homepage/about-us-link.interface';
import {BenefitInterface} from '@/shared/interfaces/store/homepage/benefit.interface';
import {FaqInterface} from '@/shared/interfaces/store/homepage/faq.interface';
import {HomepageInterface} from '@/shared/interfaces/store/homepage/homepage.interface';
import {LifecycleInterface} from '@/shared/interfaces/store/homepage/lifecycle.interface';
import {PartnerInterface} from '@/shared/interfaces/store/homepage/partner.interface';
import {ProductTopologyInterface} from '@/shared/interfaces/store/homepage/product-topology.interface';
import {SupportTeamInterface} from '@/shared/interfaces/store/homepage/support-team.interface';
import {MapModel} from '@/shared/interfaces/store/homepage/map.model';
import {getModule, Module, MutationAction, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {TeamInterface} from '@/shared/interfaces/store/homepage/team.interface';

@Module({
  dynamic: true,
  name: 'HomePage',
  store,
  namespaced: true
})
export class Homepage extends VuexModule {

  title = '';

  subtitle = '';

  airlines: string[] = [];

  faq: FaqInterface = {
    title: '',
    link: '',
    subtitle: '',
    questions: []
  };

  partners: PartnerInterface[] = [];

  benefits: BenefitInterface[] = [];

  aboutUsLinks: AboutUsLinkInterface[] = [];

  aboutUsTitle = '';

  aboutUsContent = '';

  partnersMainTitle = '';

  benefitsTitle = '';

  supportTeam: SupportTeamInterface = {
    description: '',
    image: '',
    supportItems: [],
    title: ''
  };

  businessEmail = '';

  helpEmail = '';

  contactUsImage = '';

  productTopology: ProductTopologyInterface = {
    button: '',
    content: '',
    image: '',
    mainTitle: '',
    title: ''
  };

  lifecycle: LifecycleInterface = {
    items: [],
    title: ''
  };

  team: TeamInterface = {
    content: '',
    link: '',
    title: ''
  };

  iataImage: string = '';

  map: MapModel = new MapModel();

  @MutationAction({
    mutate: [
      'faq', 'title', 'partners', 'benefits', 'aboutUsLinks', 'supportTeam', 'businessEmail', 'helpEmail', 'contactUsImage', 'subtitle',
      'airlines', 'aboutUsTitle', 'aboutUsContent', 'partnersMainTitle', 'benefitsTitle', 'productTopology', 'lifecycle', 'map',
      'team', 'iataImage'
    ]
  })
  async getHomepage(): Promise<HomepageInterface> {
    return HttpClient.get<HomepageInterface>('/home-page');
  }

}

export const HomepageStore = getModule(Homepage);
