


















import HomeMainScreen from '@/components/home/HomeMainScreen.vue';
import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';
import {OverlayService} from '@/services/overlay.service';

@Component({
  components: {
    HomeContactUs: () => import(/* webpackChunkName: "home-contact-us" */ '@/components/home/HomeContactUs.vue'),
    HomeFAQ: () => import(/* webpackChunkName: "home-faq" */ '@/components/home/HomeFAQ.vue'),
    HomeTeam: () => import(/* webpackChunkName: "home-team" */ '@/components/home/HomeTeam.vue'),
    HomeLifecycle: () => import(/* webpackChunkName: "home-lifecycle" */ '@/components/home/HomeLifecycle.vue'),
    HomeMap: () => import(/* webpackChunkName: "home-map" */ '@/components/home/HomeMap.vue'),
    HomeSupportTeam: () => import(/* webpackChunkName: "home-support-team" */ '@/components/home/HomeSupportTeam.vue'),
    HomeProductTopology: () => import(/* webpackChunkName: "home-product-topology" */ '@/components/home/HomeProductTopology.vue'),
    HomeBenefits: () => import(/* webpackChunkName: "home-benefits" */ '@/components/home/HomeBenefits.vue'),
    HomePartners: () => import(/* webpackChunkName: "home-partners" */ '@/components/home/HomePartners.vue'),
    HomeAboutUs: () => import(/* webpackChunkName: "home-about-us" */ '@/components/home/HomeAboutUs.vue'),
    HomeMainScreen
  }
})
export default class Home extends Vue {

  mounted(): void {
    HomepageStore.getHomepage()
      .then(() => OverlayService.remove());
  }

}
