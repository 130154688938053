































































import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';
import {SwiperOptions} from 'swiper';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import HomeAnimation from '@/components/home/HomeAnimation.vue';
import {UiConfigStore} from '@/store/modules/ui-config';
import {routerNavigate} from '@/services/router';

@Component({
  components: {
    HomeAnimation,
    Swiper,
    SwiperSlide
  }
})
export default class HomeMainScreen extends Vue {

  get title(): string {
    return HomepageStore.title;
  }

  get subtitle(): string {
    return HomepageStore.subtitle;
  }

  get airlines(): string[] {
    return HomepageStore.airlines;
  }

  get signUpLink(): string {
    return UiConfigStore.signUpLink;
  }

  options: SwiperOptions = {
    slidesPerView: 'auto',
    grabCursor: true,
    spaceBetween: 24,
    mousewheel: true,
    freeMode: true
  };

  open(): void {
    window.open(this.signUpLink, '_blank');
  }

  navigateToProducts(): void {
    routerNavigate.apply(this, [UiConfigStore.menu[1].link]);
  }
}
